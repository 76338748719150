<template>
  <Layout>
    <PageHeader :title="state.title" :items="state.items" />
    <div class="card rounded-3">
      <div class="card-header d-flex justify-content-between align-items-center card-header-radius">
        <h6 class="fs-16 mb-0">{{ $t('t-system-setting') }}</h6>
        <div class="d-flex" style="column-gap: 10px; height: 38px">
          <div class="col-lg-12 px-3 pb-3">
            <div class="hstack gap-2 justify-content-end">
              <button type="submit" @click="setConfig" class="btn btn-primary waves-effect waves-light w-xs pt-2 pb-2">
                {{ $t('t-update') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="outer">
        <form @submit.prevent="setConfig">
          <div class="py-4 px-2">
            <div class="d-flex flex-column ps-4 pe-4 pb-2">
              <span class="fs-16 mb-0 span-text-style">{{ $t("setting.gps") }}</span>
              <div class="gps-style">
                <div class="flex flex-column">
                  <span class="py-2 text-style">{{ $t('t-distance') }}<span class="text-danger">*</span></span>
                  <div class="flex flex-row">
                    <input v-model="state.setting.distance" type="text" class="input-style form-control" />
                    <span class="text-style">{{ $t('t-meter') }}</span>
                  </div>
                  <div v-if="v$.distance.$error" class="invalid-feedback d-flex flex-column">
                    <span v-if="v$.distance.required.$invalid">{{
                      v$.distance.required.$message
                    }}</span>

                    <span v-if="v$.distance.integer.$invalid && !v$.distance.required.$invalid">{{
                      v$.distance.integer.$message
                    }}</span>

                    <span
                      v-if="v$.distance.maxValue.$invalid && !v$.distance.integer.$invalid && !v$.distance.required.$invalid">{{
                        v$.distance.maxValue.$message
                      }}</span>

                    <span
                      v-if="v$.distance.minValue.$invalid && !v$.distance.required.$invalid && !v$.distance.integer.$invalid && !v$.distance.maxValue.$invalid">{{
                        v$.distance.minValue.$message
                      }}</span>

                  </div>
                </div>
                <div class="flex flex-column">
                  <span class="py-2 pt-4 text-style">{{ $t('t-stay-time') }}<span class="text-danger">*</span></span>
                  <div class="flex flex-row">
                    <input v-model="state.setting.timeOpen" type="text" class="input-style form-control" />
                    <span class="text-style">{{ $t('setting.stay') }}</span>
                  </div>
                  <div v-if="v$.timeOpen.$error" class="invalid-feedback d-flex flex-column">
                    <span v-if="v$.timeOpen.required.$invalid">{{
                      v$.timeOpen.required.$message
                    }}</span>

                    <span v-if="v$.timeOpen.numeric.$invalid && !v$.timeOpen.required.$invalid">{{
                      v$.timeOpen.numeric.$message
                    }}</span>

                    <span
                      v-if="v$.timeOpen.customDecimal.$invalid && !v$.timeOpen.numeric.$invalid && !v$.timeOpen.required.$invalid">{{
                        v$.timeOpen.customDecimal.$message
                      }}</span>

                    <span
                      v-if="v$.timeOpen.maxValue.$invalid && !v$.timeOpen.required.$invalid && !v$.timeOpen.customDecimal.$invalid && !v$.timeOpen.numeric.$invalid">{{
                        v$.timeOpen.maxValue.$message
                      }}</span>
                    <span
                      v-if="v$.timeOpen.minValue.$invalid && !v$.timeOpen.required.$invalid && !v$.timeOpen.maxValue.$invalid && !v$.timeOpen.customDecimal.$invalid && !v$.timeOpen.numeric.$invalid">{{
                        v$.timeOpen.minValue.$message
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column ps-4 pe-4 pt-2 pb-4">
              <span class="fs-16 mb-0 span-text-style">{{ $t("setting.cookie_head") }}</span>
              <div class="d-flex flex-column cookie-style">
                <span class="text-style">{{ $t('setting.cookie') }}</span>
                <div class="flex flex-row">
                  <input v-model="state.setting.expiredTimeCookie" type="text" class="input-style form-control" />
                  <span class="text-style">{{ $t('setting.time') }}</span>
                </div>
                <div v-if="v$.expiredTimeCookie.$error" class="invalid-feedback d-flex flex-column">
                  <span>{{
                    getExpiredTimeCookieErrMsg(v$.expiredTimeCookie)
                  }}</span>

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card rounded-3">
      <div class="card-header d-flex justify-content-between card-header-radius">
        <h6 class="fs-16 mt-1 mb-0">{{ $t('setting.whitelist') }}</h6>
        <div class="d-flex align-items-center gap-1">
          <div class="form-check form-switch">
            <input @change="() => toggleWhitelist()" class="form-check-input checkbox" type="checkbox" role="switch"
              id="showWhitelist" v-model="state.showWhitelist">
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-end pe-3">
          <b-button @click="() => process(true)" variant="dark" class="waves-effect waves-light w-sm pt-2 pb-2" size="sm"
            data-text="追加">
            <span>{{ $t('t-create') }}</span>
          </b-button>
        </div>
        <TableCommon :hasOption="false" ref="tableWhitelist" :columns="columns"
          :endpoint="ADMIN_API_PATH.ADMIN_WHITELIST" />
      </div>
    </div>
    <b-modal size="md" v-model="state.openWhitelist" hide-footer class="v-modal-custom" centered>
      <template #title>
        <span class="font-weight-bold">{{ state.whitelistId ? $t('setting.editWhitelist') : $t('setting.createWhitelist')
        }}</span>
      </template>
      <form @submit.prevent="createOrUpdate">
        <div class="row gap-2">
          <div class="col-lg-12">
            <label>{{ $t('setting.username') }} <span class="text-danger">*</span></label>
            <input :placeholder="$t('placeholder.inputNoDot', { field: $t('setting.username') })" class="form-control"
              v-model="state.whitelist.username" :maxlength="255" />
            <div v-if="vWl.username.$error" class="invalid-feedback d-flex flex-column">
              <span v-if="vWl.username.required.$invalid">{{
                vWl.username.required.$message
              }}</span>

              <span v-if="!vWl.username.required.$invalid && vWl.username.maxLength.$invalid">{{
                vWl.username.maxLength.$message
              }}</span>
            </div>
          </div>
          <div class="col-lg-12">
            <label>{{ $t('setting.ipForm') }} <span class="text-danger">*</span></label>
            <input :placeholder="$t('placeholder.inputNoDot', { field: $t('setting.ipForm') })" :maxlength="255"
              class="form-control" v-model="state.whitelist.ip" />
            <div v-if="vWl.ip.$error" class="invalid-feedback d-flex flex-column">
              <span v-if="vWl.ip.required.$invalid">{{
                vWl.ip.required.$message
              }}</span>

              <span v-if="!vWl.ip.required.$invalid && vWl.ip.ipAddress.$invalid">{{
                vWl.ip.ipAddress.$message
              }}</span>
            </div>
          </div>
          <div class="col-lg-12">
            <label>{{ $t('setting.remarks') }}</label>
            <textarea rows="4" :maxlength="1000" v-model="state.whitelist.description" type="text" class="form-control" />
          </div>
          <div class="col-lg-12">
            <label>{{ $t('t-enable-disable') }} <span class="text-danger">*</span></label>
            <div class="form-check form-switch mb-2">
              <input class="form-check-input checkbox" type="checkbox" role="switch" id="status3"
                v-model="state.whitelist.status">
            </div>
          </div>
          <div class="col-lg-12">
            <div class="d-flex gap-1 align-items-center justify-content-end">
              <b-button type="button" variant="success" @click="() => process(false)">{{ $t('t-cancel') }}</b-button>
              <b-button v-if="state.whitelistId" type="button" variant="danger" @click="() => deleteWhenEdit()">{{
                $t('t-delete') }}</b-button>
              <b-button type="submit" variant="primary">{{ state.whitelistId ? $t('t-submit-update') :
                $t('t-submit-create') }}</b-button>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<script>
import { onMounted, reactive, computed, h, ref } from "vue";
import { cloneDeep, get } from "lodash";
import Swal from "sweetalert2";
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import { ROLES } from "@/constants";
import { systemService } from "@/services";
import i18n from "@/i18n";
import useVuelidate from '@vuelidate/core'
import { required, helpers, minValue, maxValue, integer, numeric, maxLength, ipAddress } from "@vuelidate/validators";
import TableCommon from "@/components/common/TableCommonVue3.vue";
import { ADMIN_API_PATH } from '@/constants';
import moment from "moment";
import { whitelistService } from '../../../services';

export default {
  components: {
    Layout,
    PageHeader,
    TableCommon
  },

  setup() {
    const initDefaultWhitelist = {
      username: '',
      ip: '',
      description: '',
      status: true,
    }
    const state = reactive({
      query: {
        search: '',
        page: 1,
        pageSize: 10,
        role: ROLES.ADMIN
      },
      items: [
        {
          text: i18n.global.t('t-setting_'),
          active: true
        },
        {
          text: i18n.global.t('t-system-setting'),
          active: true,
        },
      ],
      loading: false,
      setting: {
        expiredTimeCookie: null,
        distance: 1,
        timeOpen: 0.5,
      },
      openWhitelist: false,
      title: i18n.global.t('t-setting_'),
      whitelistId: '',
      whitelist: {
        ...initDefaultWhitelist
      },
      showWhitelist: false,
      currentList: []
    })

    const setting = computed(() => state.setting)
    const tableWhitelist = ref(null)

    const columns = [
      {
        sortable: false,
        label: '',
        type: 'stt',
        getShowColumn: (hasItem) => hasItem,
        maxWidth: 60,
        renderCell: (whitelist, rowIndex) => {
          return h('span', rowIndex + 1)
        }
      },
      {
        key: 'username',
        headerClass: 'text-center',
        cellClass: 'text-center',
        sortable: true,
        label: i18n.global.t('setting.username'),
        maxWidth: 120,
      },
      {
        key: 'ip',
        headerClass: 'text-center',
        cellClass: 'text-center',
        sortable: true,
        label: i18n.global.t('setting.ipForm'),
        maxWidth: 120,
      },
      {
        key: 'description',
        headerClass: 'text-center margin-header',
        cellClass: 'text-center',
        sortable: false,
        label: i18n.global.t('setting.remarks'),
        maxWidth: 120,
      },
      {
        key: 'status',
        headerClass: 'text-center margin-header',
        sortable: false,
        label: i18n.global.t('setting.status'),
        renderCell: (whitelist) => {
          if (whitelist.status === 'ACTIVE') {
            return h('div', { class: 'd-flex flex-row align-items-center justify-content-center' }, [
              h('i', { class: 'bx bx-check-circle fs-18 text-success' }),
              h('p', { class: 'mb-0 ps-1 fw-semibold text-normal' }, i18n.global.t("t-enable"))
            ])
          } else {
            return h('div', { class: 'd-flex flex-row align-items-center justify-content-center' }, [
              h('i', { class: 'bx bx-x-circle fs-18 text-danger' }),
              h('p', { class: 'mb-0 ps-1 fw-semibold text-normal' }, i18n.global.t("t-disable"))
            ])
          }
        }
      },
      {
        key: 'createdAt',
        headerClass: 'text-center',
        sortable: true,
        label: i18n.global.t('setting.createdAt'),
        renderCell: (whitelist) => {
          return h('div', { class: 'text-center' }, moment(whitelist.createdAt).format('YYYY-MM-DD HH:mm:ss'))
        }
      },
      {
        key: 'updatedAt',
        headerClass: 'text-center',
        sortable: true,
        label: i18n.global.t('setting.updatedAt'),
        renderCell: (whitelist) => {
          return h('div', { class: 'text-center' }, moment(whitelist.updatedAt).format('YYYY-MM-DD HH:mm:ss'))
        }
      },
      {
        key: 'action',
        type: 'action',
        sortable: false,
        label: '',
        getShowColumn: (hasItem) => hasItem,
        renderCell: (whitelist) => {
          return <div class="d-flex gap-2">
            <b-button size="md" onClick={() => edit(whitelist)} variant="primary">{i18n.global.t('t-edit')}</b-button>
            <b-button size="md" onClick={() => deleteWhitelist(whitelist)} variant="danger">{i18n.global.t('t-delete')}</b-button>
          </div>
        }
      }
    ]

    const getExpiredTimeCookieErrMsg = (expiredTimeCookieValidator) => {
      if (expiredTimeCookieValidator.numeric.$invalid) {
        return expiredTimeCookieValidator.numeric.$message
      } else if (expiredTimeCookieValidator.minValue.$invalid) {
        return expiredTimeCookieValidator.minValue.$message
      } else if (expiredTimeCookieValidator.customDecimal.$invalid) {
        return expiredTimeCookieValidator.customDecimal.$message
      }
    }

    const reset = () => {
      state.whitelist = { ...initDefaultWhitelist }
      state.whitelistId = ''
      vWl.value.$reset()
    }

    /**
     *
     * @param {Boolean} open
     */
    const process = (open) => {
      reset()
      state.openWhitelist = open
    }

    const deleteWhenEdit = async () => {
      await deleteWhitelist({ ip: state.whitelist.ip, id: state.whitelistId })
    }

    const createOrUpdate = async () => {
      try {
        const { username, ip, description, status } = state.whitelist
        state.whitelist = {
          username: username.trim(),
          ip: ip.trim(),
          description: description.trim(),
          status
        }
        vWl.value.$touch();
        if (vWl.value.$invalid) return;
        const isCreate = state.whitelistId ? false : true

        const params = {
          ...state.whitelist,
          status: state.whitelist.status ? 'ACTIVE' : 'INACTIVE'
        }

        if (isCreate) {
          await whitelistService.createWhitelist(params)
        } else {
          const condition_MSG_077 = state.currentList.length === 1 && params.status === 'INACTIVE'
          if (condition_MSG_077) {
            await Swal.fire({
              title: "",
              text: i18n.global.t('msg.MSG_077'),
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
            return;
          }
          await whitelistService.updateWhitelist(state.whitelistId, params)
        }

        await Swal.fire({
          title: "",
          text: i18n.global.t('msg.saved'),
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });

        reset()
        state.openWhitelist = false

        tableWhitelist.value.refreshTable()
        getListWhitelist()

      } catch (e) {
        const { data: { message = '' } } = e.response
        if (message.includes('already exists')) {
          Swal.fire({
            title: "",
            icon: 'error',
            text: i18n.global.t('msg.alreadyExists', { item: state.whitelist.ip, field: i18n.global.t('setting.ipForm') }),
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          Swal.fire({
            title: "",
            icon: 'error',
            text: message,
            showConfirmButton: false,
            timer: 1500,
          })
        }
        console.log('createOrUpdate', e)
      }
    }

    const edit = (whitelist) => {
      const { username, description, ip, status, id } = whitelist

      state.whitelistId = id;
      state.whitelist = {
        username,
        description,
        ip,
        status: status === 'ACTIVE' ? true : false
      }

      state.openWhitelist = true
    }

    const deleteWhitelist = async (whitelist) => {
      try {
        const accept = await Swal.fire({
          title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('deleted_message', { message: whitelist.ip })}</h2>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: i18n.global.t('yes'),
          cancelButtonText: i18n.global.t('t-cancel'),
        });

        if (accept.value) {
          await whitelistService.deleteWhitelist(whitelist.id)
          reset()
          state.openWhitelist = false
          tableWhitelist.value.refreshTable()
          getListWhitelist()
        }
      } catch (e) {
        console.log(e, 'deleteWhitelist')
      }
    }

    const rules = {
      expiredTimeCookie: {
        //required: helpers.withMessage(i18n.global.t('field_required', { field: i18n.global.t('t-set-cookie') }), required),
        minValue: helpers.withMessage(i18n.global.t('msg.cookieMin', { cookieMin: 0.5 }), minValue(0.5)),
        //maxValue: helpers.withMessage(i18n.global.t('msg.cookieMax', { cookieMax: 100 }), maxValue(100)),
        numeric: helpers.withMessage(i18n.global.t('msg.onlyNumberWithDot', { field: i18n.global.t('t-set-cookie') }), numeric),
        customDecimal: {
          $message() {
            return i18n.global.t('msg.single-digit-decimal', { num: 1 })
          },
          $validator() {
            if (!setting.value.expiredTimeCookie || (setting.value.expiredTimeCookie && /^\d{0,}(\.\d{1})?$/.test(setting.value.expiredTimeCookie))) {
              return true;
            }
            return false
          }
        }
      },
      distance: {
        required: helpers.withMessage(i18n.global.t('field_required', { field: i18n.global.t('t-distance') }), required),
        minValue: helpers.withMessage(i18n.global.t('msg.distanceMin', { distanceMin: 1 }), minValue(1)),
        maxValue: helpers.withMessage(i18n.global.t('msg.distanceMax', { distanceMax: 10000 }), maxValue(10000)),
        integer: helpers.withMessage(i18n.global.t('msg.onlyNumber', { field: i18n.global.t('t-distance') }), integer)
      },
      timeOpen: {
        required: helpers.withMessage(i18n.global.t('field_required', { field: i18n.global.t('t-stay-time') }), required),
        minValue: helpers.withMessage(i18n.global.t('msg.timeMin', { timeMin: 0.5 }), minValue(0.5)),
        maxValue: helpers.withMessage(i18n.global.t('msg.timeMax', { timeMax: 12 }), maxValue(12)),
        numeric: helpers.withMessage(i18n.global.t('msg.onlyNumberWithDot', { field: i18n.global.t('t-stay-time') }), numeric),
        customDecimal: {
          $message() {
            return i18n.global.t('msg.single-digit-decimal', { num: 1 })
          },
          $validator() {
            if (!setting.value.timeOpen || /^\d{0,2}(\.\d{1})?$/.test(setting.value.timeOpen)) {
              return true;
            }
            return false
          }
        }
      }
    }

    const v$ = useVuelidate(rules, setting)

    const whitelist = computed(() => state.whitelist)

    const rulesWhitelist = {
      username: {
        required: helpers.withMessage(i18n.global.t('field_required', { field: i18n.global.t('setting.username') }), required),
        maxLength: helpers.withMessage(i18n.global.t('field_maxlength', { maxlength: 255, field: i18n.global.t('setting.username') }), maxLength(255)),
      },
      ip: {
        required: helpers.withMessage(i18n.global.t('field_required', { field: i18n.global.t('setting.ipForm') }), required),
        ipAddress: helpers.withMessage(i18n.global.t('msg.invalid_field', { field: i18n.global.t('setting.ipForm') }), ipAddress),
      }
    }

    const vWl = useVuelidate(rulesWhitelist, whitelist)

    const setConfig = async () => {
      v$.value.$touch();
      if (v$.value.$invalid) return;

      try {
        const response = await systemService.setConfigSystem({
          expiredTimeCookie: Number(state.setting.expiredTimeCookie),
          distance: Number(state.setting.distance),
          timeOpen: Number(state.setting.timeOpen)
        });
        if (response) {
          await Swal.fire({
            title: "",
            text: i18n.global.t('msg.saved'),
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (e) {
        console.log('setConfig', e);
      }
    }

    const getConfig = async () => {
      try {
        state.loading = true;
        let res = await systemService.getSystemConfig();
        if (res) {
          const { id, ...rest } = res;
          if(!rest.expiredTimeCookie || (rest.expiredTimeCookie && Number(rest.expiredTimeCookie) < 0.5) ) {
            rest.expiredTimeCookie = null
          }
          state.setting = cloneDeep(rest);

        }
      } catch (e) {
        console.log('error', e)
      } finally {
        state.loading = false;
      }
    }

    const getToggleWhitelist = async () => {
      try {
        const response = await whitelistService.getToggleWhitelist()
        state.showWhitelist = response.status === 'INACTIVE' ? false : true
      } catch (e) {
        console.log(e, 'getToggleWhitelist')
      }
    }

    const getListWhitelist = async () => {
      try {
        const response = await whitelistService.getListWhitelist({ page: 1, pageSize: 1000 })
        state.currentList = response
        toggleWhitelist()
      } catch (e) {
        console.log(e, 'getToggleWhitelist')
      }
    }

    const toggleWhitelist = async () => {
      let status = ''
      if (state.currentList.length === 0) {
        status = 'INACTIVE'
      } else {
        status = state.showWhitelist ? 'ACTIVE' : 'INACTIVE'
      }
      try {
        await whitelistService.toggleWhitelist({ status })
      } catch (e) {
        console.log(e, 'toggleWhitelist')
      }
    }

    onMounted(() => {
      getConfig()
      getToggleWhitelist()
      getListWhitelist()
    })

    return {
      state,
      get,
      getConfig,
      setConfig,
      v$,
      getExpiredTimeCookieErrMsg,
      ADMIN_API_PATH,
      columns,
      createOrUpdate,
      process,
      vWl,
      tableWhitelist,
      toggleWhitelist,
      deleteWhenEdit
    }
  },
}

</script>

<style scoped>
.cookie-style {
  margin: 20px 0 0 15px;
}

.gps-style {
  margin: 10px 0 0 15px
}

.input-style {
  width: 208px;
  margin-right: 15px;
  text-align: right;
}

.text-style {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.span-text-style {
  font-size: 24px;
}

.card-header-radius {
  border-radius: 7px 7px 0 0;
  font-size: 16px;
  color: var(--vz-heading-color);
}

.pe-45 {
  padding-right: 2rem;
}
</style>
